var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":(this.itemId === 'new' ? 'Link' : _vm.$t('save')),"title":_vm.$t('teamMember', {expr: this.itemId === 'new' ? _vm.$t('nnew') : _vm.$t('edit')})},on:{"save":function($event){return _vm.loadItems()}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('TeamMemberForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}],null,false,954445673),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('YesNoDialog',{attrs:{"title":_vm.$t('deleteTeamMember'),"text":_vm.$t('askDeleteTMem')},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('team2')))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","disabled":_vm.userRole === 'owner'},on:{"click":function($event){return _vm.leftProject()}}},[_vm._v("Leave Project ")]),(_vm.can(_vm.USER_PERMISSIONS.manageProjectUsers))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem('new')}}},[_vm._v(_vm._s(_vm.$t('newTeamMember'))+" ")]):_vm._e()],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":Number.MAX_VALUE},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [(_vm.can(_vm.USER_PERMISSIONS.manageProjectUsers))?_c('v-select',{style:({width: '200px'}),attrs:{"items":_vm.USER_ROLES,"item-value":"slug","item-text":"title"},on:{"change":function($event){return _vm.updateProjectUser(item)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}}):_c('div',[_vm._v(_vm._s(_vm.USER_ROLES.find(function (r) { return r.slug === item.role; }).title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.can(_vm.USER_PERMISSIONS.manageProjectUsers))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,false,2402537090)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }